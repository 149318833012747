// src/contexts/utils/privateRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const PrivateRoute = ({ children }) => {
  const { currentUser, guestUser, loading } = useAuth();

  if (loading) {
    // Optionally, render a loading spinner or placeholder
    return <div>Loading...</div>;
  }

  if (currentUser || guestUser) {
    return children;
  }

  return <Navigate to="/" replace />;
};

export default PrivateRoute;