// src/contexts/RawDataContext.jsx

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { collection, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { useFirebase } from './FirebaseContext';
import { useAuth } from './AuthContext';
import { useTheme } from '@mui/material/styles';
import { format } from 'd3-format';

// Create the RawDataContext
const RawDataContext = createContext();

// Custom hook to use the RawDataContext
export const useRawData = () => useContext(RawDataContext);

// RawDataProvider component
export const RawDataProvider = ({ children }) => {
  // COMMON SECTION
  // ----------------------------------------------------------------
  // State variables for raw data
  const [deals, setDeals] = useState([]);
  const [owners, setOwners] = useState({});
  const [pipelineStages, setPipelineStages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Firebase and Auth contexts
  const { db } = useFirebase();
  const { currentUser } = useAuth();
  const theme = useTheme();

  // Data fetching effect
  useEffect(() => {
    if (!currentUser) {
      setDeals([]);
      setOwners({});
      setPipelineStages([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const dealsRef = collection(db, 'users', currentUser.uid, 'deals');
    const ownersRef = collection(db, 'users', currentUser.uid, 'owners');
    const pipelineStagesRef = doc(db, 'users', currentUser.uid, 'pipelineStages', 'stages');

    // Fetch deals
    const unsubscribeDeals = onSnapshot(
      dealsRef,
      (snapshot) => {
        const dealsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDeals(dealsData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching deals:', err);
        setError(err);
        setLoading(false);
      }
    );

    // Fetch owners
    const unsubscribeOwners = onSnapshot(
      ownersRef,
      (snapshot) => {
        const ownersData = {};
        snapshot.forEach((doc) => {
          ownersData[doc.id] = doc.data();
        });
        setOwners(ownersData);
      },
      (err) => {
        console.error('Error fetching owners:', err);
        setError(err);
      }
    );

    // Fetch pipeline stages
    getDoc(pipelineStagesRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          setPipelineStages(docSnapshot.data().stages || []);
        }
      })
      .catch((err) => {
        console.error('Error fetching pipeline stages:', err);
        setError(err);
      });

    // Cleanup subscriptions on unmount
    return () => {
      unsubscribeDeals();
      unsubscribeOwners();
    };
  }, [db, currentUser]);

  // Common helper functions
  const formatCurrency = (value) => {
    const formatter = format('$,.0f');
    return formatter(value);
  };

  const formatPercentage = (value) => {
    const formatter = format('.1%');
    return formatter(value);
  };

  const getStageLabel = (stageId) => {
    const stage = pipelineStages.find((s) => s.id === stageId);
    return stage ? stage.label : 'Unknown Stage';
  };

  // COMMON CALCULATIONS (used across all dashboards)
  // ----------------------------------------------------------------
  const calculateCommonMetrics = useCallback(() => {
    const totalDeals = deals.length;
    const totalRevenue = deals.reduce((sum, deal) => sum + (deal.amount || 0), 0);
    const averageDealSize = totalRevenue / (totalDeals || 1);

    return {
      totalDeals,
      totalRevenue,
      averageDealSize,
    };
  }, [deals]);

  // HEALTHCHECK SECTION
  // ----------------------------------------------------------------
  // Healthcheck-specific metrics
  const [overallHealthMetrics, setOverallHealthMetrics] = useState({});
  const calculateHealthcheckMetrics = useCallback(() => {
    const openDeals = deals.filter((deal) => deal.isOpenDeal);
    const totalOpenDeals = openDeals.length;
    const averageDealAge =
      openDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalOpenDeals || 1);
    const averageSalesCycle =
      openDeals.reduce((sum, deal) => sum + (deal.salesCycleDays || 0), 0) / (totalOpenDeals || 1);

    const now = new Date();
    const dealsPastAvgCycle = openDeals.filter((deal) => {
      const createDate = new Date(deal.createdate);
      return (now - createDate) / (1000 * 60 * 60 * 24) > averageSalesCycle;
    }).length;

    const totalQuality = openDeals.reduce(
      (sum, deal) => sum + (deal.hs_deal_stage_probability || 0),
      0
    );
    const pipelineFreshness = (totalQuality / (totalOpenDeals || 1)) * 100;

    setOverallHealthMetrics({
      totalOpenDeals: { value: totalOpenDeals, title: 'Total Open Deals' },
      dealsPastAvgCycle: { value: dealsPastAvgCycle, title: 'Deals Past Avg Cycle' },
      pipelineFreshness: { value: pipelineFreshness, title: 'Pipeline Freshness' },
      averageSalesCycle: { value: averageSalesCycle, title: 'Avg Sales Cycle' },
    });
  }, [deals]);

  // Owner metrics for Healthcheck
  const calculateOwnerHealthMetrics = useCallback(() => {
    const now = new Date();
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startOfQuarter = new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1);
    const endOfQuarter = new Date(startOfQuarter.getFullYear(), startOfQuarter.getMonth() + 3, 0);

    return Object.values(owners).map((owner) => {
      const ownerDeals = deals.filter((deal) => deal.hubspot_owner_id === owner.id);
      const totalDeals = ownerDeals.length;
      const totalValue = ownerDeals.reduce((sum, deal) => sum + (deal.amount || 0), 0) || 0;

      // Existing Metrics
      const outdatedDeals = ownerDeals.filter(
        (deal) => deal.pastDue === 1 || deal.missingAmount === 1 || deal.missingCloseDate === 1
      ).length;
      const avgDealAge =
        ownerDeals.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (totalDeals || 1);
      const dealsClosingSoon = ownerDeals.filter((deal) => deal.closingSoon === 1).length;
      const avgWinProbability =
        ownerDeals.reduce((sum, deal) => sum + (deal.dealWinProbability || 0), 0) /
        (totalDeals || 1);
      const revenueAtRisk = ownerDeals
        .filter((deal) => deal.criticalBad === 1)
        .reduce((sum, deal) => sum + (deal.amount || 0), 0);
      const dealVelocity =
        ownerDeals.reduce((sum, deal) => sum + (deal.dealVelocity || 0), 0) / (totalDeals || 1);
      const forecastAccuracy =
        ownerDeals.reduce((sum, deal) => sum + (deal.forecastAccuracy || 0), 0) / (totalDeals || 1);

      // Deals Created This Quarter
      const dealsCreatedThisQuarter = ownerDeals.filter((deal) => {
        const createdDate = new Date(deal.createdate);
        return createdDate >= startOfQuarter && createdDate <= endOfQuarter;
      }).length;

      // Deals Closing This Quarter
      const dealsClosingThisQuarter = ownerDeals.filter((deal) => {
        const closeDate = new Date(deal.closedate);
        return closeDate >= startOfQuarter && closeDate <= endOfQuarter;
      }).length;

      // Needs Attention Deals
      const needsAttentionDeals = ownerDeals.filter((deal) => deal.needsAttention === 1).length;

      // Average Number of Associated Contacts
      const avgNumAssociatedContacts =
        ownerDeals.reduce((sum, deal) => sum + (deal.num_associated_contacts || 0), 0) /
        (totalDeals || 1);

      // Average Lead Conversion Rate
      const avgLeadConversionRate =
        ownerDeals.reduce((sum, deal) => sum + (deal.leadConversionRate || 0), 0) /
        (totalDeals || 1);

      // Total Forecast Amount
      const totalForecastAmount = ownerDeals.reduce(
        (sum, deal) => sum + (deal.hs_forecast_amount || 0),
        0
      );

      // Total Projected Amount
      const totalProjectedAmount = ownerDeals.reduce(
        (sum, deal) => sum + (deal.hs_projected_amount || 0),
        0
      );

      // Total MRR and ARR
      const totalMRR = ownerDeals.reduce((sum, deal) => sum + (deal.hs_mrr || 0), 0);
      const totalARR = ownerDeals.reduce((sum, deal) => sum + (deal.hs_arr || 0), 0);

      // Average Time Since Last Contacted
      const avgTimeSinceLastContacted =
        ownerDeals.reduce((sum, deal) => {
          if (deal.notes_last_contacted) {
            const lastContactedDate = new Date(deal.notes_last_contacted);
            const daysSinceLastContact = (now - lastContactedDate) / (1000 * 3600 * 24);
            return sum + daysSinceLastContact;
          } else {
            return sum + 0;
          }
        }, 0) / (totalDeals || 1);

      // Deals with No Scheduled Next Activity
      const dealsWithNoNextActivity = ownerDeals.filter((deal) => {
        return !deal.nextactivitydate;
      }).length;

      // Average Time in Deal Stage
      const avgTimeInDealStage =
        ownerDeals.reduce((sum, deal) => {
          if (deal.hs_time_in_dealstage && deal.hs_time_in_dealstage.current) {
            return sum + deal.hs_time_in_dealstage.current / (1000 * 3600 * 24); // Convert ms to days
          } else {
            return sum + 0;
          }
        }, 0) / (totalDeals || 1);

      // Engagement Metrics
      const totalNotes = ownerDeals.reduce((sum, deal) => sum + (deal.num_notes || 0), 0);
      const totalContactedNotes = ownerDeals.reduce(
        (sum, deal) => sum + (deal.num_contacted_notes || 0),
        0
      );
      const totalAssociatedContacts = ownerDeals.reduce(
        (sum, deal) => sum + (deal.num_associated_contacts || 0),
        0
      );

      const dealsWithNextActivity = ownerDeals.filter((deal) => deal.nextactivitydate).length;

      const dealsContactedRecently = ownerDeals.filter((deal) => {
        if (deal.notes_last_contacted) {
          const lastContactedDate = new Date(deal.notes_last_contacted);
          return lastContactedDate >= sevenDaysAgo;
        }
        return false;
      }).length;

      // Calculated avgNotesPerDeal using totalNotes
      const avgNotesPerDeal = totalNotes / (totalDeals || 1);
      const percentageDealsWithNextActivity =
        (dealsWithNextActivity / (totalDeals || 1)) * 100;
      const percentageDealsContactedRecently =
        (dealsContactedRecently / (totalDeals || 1)) * 100;

      // Engagement Score Calculation
      const idealNotesPerDeal = 5; // Adjust as per your standard
      const engagementScore =
        (
          (avgNotesPerDeal / idealNotesPerDeal) * 0.4 +
          (percentageDealsWithNextActivity / 100) * 0.3 +
          (percentageDealsContactedRecently / 100) * 0.3
        ) * 100;

      // Risk Metrics
      const percentageDealsWithNoNextActivity = 100 - percentageDealsWithNextActivity;
      const percentageDealsNotContactedRecently = 100 - percentageDealsContactedRecently;

      // Risk Score Calculation
      const riskScore =
        (
          (percentageDealsWithNoNextActivity / 100) * 0.5 +
          (percentageDealsNotContactedRecently / 100) * 0.5
        ) * 100;

      // Ensure scores are between 0 and 100
      const normalizedEngagementScore = Math.min(Math.max(engagementScore, 0), 100);
      const normalizedRiskScore = Math.min(Math.max(riskScore, 0), 100);

      // Health Score Calculation
      const healthScore =
        ((1 - outdatedDeals / (totalDeals || 1)) * 0.25 +
          avgWinProbability * 0.15 +
          (normalizedEngagementScore / 100) * 0.15 +
          (1 - revenueAtRisk / (totalValue || 1)) * 0.15 +
          forecastAccuracy * 0.1 +
          (1 - needsAttentionDeals / (totalDeals || 1)) * 0.2) *
        100;

      return {
        id: owner.id,
        owner: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
        totalDeals,
        totalValue,
        outdatedDeals,
        avgDealAge,
        dealsClosingSoon,
        avgWinProbability,
        healthScore: Math.min(Math.max(healthScore, 0), 100),
        revenueAtRisk,
        dealVelocity,
        forecastAccuracy,
        engagementScore: normalizedEngagementScore,
        riskScore: normalizedRiskScore,
        dealsCreatedThisQuarter,
        dealsClosingThisQuarter,
        needsAttentionDeals,
        avgNumAssociatedContacts,
        avgNotesPerDeal,
        avgLeadConversionRate,
        totalForecastAmount,
        totalProjectedAmount,
        totalMRR,
        totalARR,
        avgTimeSinceLastContacted,
        dealsWithNoNextActivity,
        avgTimeInDealStage,
        // Additional metrics
        totalNotes,
        totalContactedNotes,
        totalAssociatedContacts,
        dealsWithNextActivity,
        dealsContactedRecently,
        percentageDealsWithNextActivity,
        percentageDealsContactedRecently,
        percentageDealsWithNoNextActivity,
        percentageDealsNotContactedRecently,
      };
    });
  }, [deals, owners]);

  // INSIGHTS SECTION
  // ----------------------------------------------------------------
  // Insights-specific state and functions
  const [filters, setFilters] = useState({});
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [insights, setInsights] = useState([]);

  // Apply filters to deals
  const applyFilters = useCallback(
    (dealsData, filters) => {
      let filtered = [...dealsData];

      // Date Range Filter
      if (filters.dateRange && filters.dateRange[0] && filters.dateRange[1]) {
        const [startDate, endDate] = filters.dateRange;
        filtered = filtered.filter((deal) => {
          const dealDate = new Date(deal.createdate);
          return dealDate >= startDate && dealDate <= endDate;
        });
      }

      // Pipelines Filter
      if (filters.pipelines && filters.pipelines.length > 0) {
        filtered = filtered.filter((deal) => filters.pipelines.includes(deal.pipeline));
      }

      // Deal Stages Filter
      if (filters.dealStages && filters.dealStages.length > 0) {
        filtered = filtered.filter((deal) => filters.dealStages.includes(deal.dealstage));
      }

      // Owners Filter
      if (filters.owners && filters.owners.length > 0) {
        filtered = filtered.filter((deal) => filters.owners.includes(deal.hubspot_owner_id));
      }

      // Include Closed Deals Filter
      if (!filters.includeClosedDeals) {
        filtered = filtered.filter((deal) => deal.isOpenDeal);
      }

      return filtered;
    },
    []
  );

  // Perform calculations for insights
  const performInsightsCalculations = useCallback(
    (dealsData) => {
      const totalRevenue = dealsData.reduce((sum, deal) => sum + (deal.amount || 0), 0);
      const averageDealSize = totalRevenue / (dealsData.length || 1);
      const averageDealAge =
        dealsData.reduce((sum, deal) => sum + (deal.daysOpen || 0), 0) / (dealsData.length || 1);

      const dealsByMonth = {};
      dealsData.forEach((deal) => {
        const month = new Date(deal.createdate).toLocaleString('default', {
          month: 'short',
          year: 'numeric',
        });
        if (!dealsByMonth[month]) {
          dealsByMonth[month] = { count: 0, totalAmount: 0 };
        }
        dealsByMonth[month].count += 1;
        dealsByMonth[month].totalAmount += deal.amount || 0;
      });

      const cohortData = Object.keys(dealsByMonth).map((month) => ({
        month,
        ...dealsByMonth[month],
      }));

      return {
        revenue: {
          type: 'revenue',
          title: 'Total Revenue',
          value: totalRevenue,
          insight: `Total revenue: ${formatCurrency(totalRevenue)}`,
          historicalData: cohortData.map((data) => data.totalAmount),
        },
        // ...other insights
      };
    },
    [formatCurrency]
  );

  // Update filters and compute insights
  const updateFilters = useCallback(
    (newFilters) => {
      setFilters(newFilters);
      const filtered = applyFilters(deals, newFilters);
      setFilteredDeals(filtered);

      const calculatedInsights = performInsightsCalculations(filtered);
      setInsights(Object.values(calculatedInsights));
    },
    [deals, applyFilters, performInsightsCalculations]
  );

  // REVENUE SECTION
  // ----------------------------------------------------------------
  // Revenue-specific calculations
  const [revenueMetrics, setRevenueMetrics] = useState({});
  const calculateRevenueMetrics = useCallback(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const lastYear = currentYear - 1;

    // Filter deals for current and last year with hs_deal_stage_probability of 1.0 or 0.0
    const closedDeals = deals.filter(
      (deal) =>
        (deal.hs_deal_stage_probability === 1.0 || deal.hs_deal_stage_probability === 0.0) &&
        (new Date(deal.closedate).getFullYear() === currentYear ||
          new Date(deal.closedate).getFullYear() === lastYear)
    );

    // Aggregate revenue data by month for current and last year
    const revenueByMonth = closedDeals.reduce(
      (acc, deal) => {
        const closeDate = new Date(deal.closedate);
        const year = closeDate.getFullYear();
        const month = closeDate.getMonth(); // 0-11
        const amount = deal.amount || 0;

        if (year === currentYear) {
          acc.currentYear[month] = (acc.currentYear[month] || 0) + amount;
        } else if (year === lastYear) {
          acc.lastYear[month] = (acc.lastYear[month] || 0) + amount;
        }
        return acc;
      },
      { currentYear: {}, lastYear: {} }
    );

    // Prepare data for chart
    const yoyRevenueData = Array.from({ length: 12 }, (_, month) => ({
      month: new Date(0, month).toLocaleString('default', { month: 'short' }),
      [currentYear]: revenueByMonth.currentYear[month] || 0,
      [lastYear]: revenueByMonth.lastYear[month] || 0,
      forecast: 0, // Placeholder for forecasted data
    }));

    // Implement forecasting logic (e.g., linear regression, moving average)
    // For now, we'll leave forecast values as placeholders

    // Update state
    setRevenueMetrics({
      yoyRevenueData,
      // ...other revenue metrics
    });
  }, [deals]);

  // Effect to recalculate metrics when deals change
  useEffect(() => {
    calculateHealthcheckMetrics();
    calculateRevenueMetrics();
    // Add other calculations as needed
  }, [deals, calculateHealthcheckMetrics, calculateRevenueMetrics]);

  // Prepare value to be provided by the context
  const value = {
    // Common data and functions
    deals,
    owners,
    pipelineStages,
    loading,
    error,
    formatCurrency,
    formatPercentage,
    getStageLabel,
    calculateCommonMetrics,

    // Healthcheck data and functions
    overallHealthMetrics,
    calculateOwnerHealthMetrics,

    // Insights data and functions
    filters,
    filteredDeals,
    insights,
    updateFilters,

    // Revenue data and functions
    revenueMetrics,
    // ...other revenue-related functions
  };

  return <RawDataContext.Provider value={value}>{children}</RawDataContext.Provider>;
};